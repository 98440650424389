/* .xen-left-pannel{
    width: 100%;
} */

.main{
    overflow-x: hidden !important;
    width: 100%;
    height: 100%;
    margin-top: 70px;
    margin-bottom: 50px;
}

.left{
    margin-right: -30px;
}

.xen-d-grid{
    display: grid;
    grid-template-columns:1fr 2fr ;
}
#background{
    position:absolute;
    z-index:-1;
    background:white;
    display:block;
    min-height:70%; 
    min-width:90%;
    color:yellow;
    background-repeat: repeat;
    transform:rotate(-45deg) !important;
    /* background-image: url("../../assets/icons/watermark.svg"); */
}
.watermark
{
    /* position:absolute; */
    position: fixed;
    z-index:-1;
    background:white;
    display:block;
    max-height:100%; 
    word-spacing: 3em;
    max-width:100%;
    /* margin-top: -20%; */
    text-align: center;
    /* margin-left: -20%; */
    color: #e2e2e2;
    font-size:1.5rem;
    overflow-y: hidden;
    transform:rotate(-45deg) !important;
    background: repeat;
    overflow: hidden;
    /* background-image: url("../../assets/icons/watermark.svg"); */
}
.question-text img{
    width: max-content;
    height: 100%;
}

@media only screen and (max-width:1200px){
    
}