.react-pdf__Page {
    margin-top: auto;
  }
  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
    min-width: 1056px;
    min-height: 816px;
  }
  
