.dcm-subject-tabs{
    background-color: #ffffff;
    width: 100%;
    color: white !important;
}

.dcm-subject-tab{
    color: black !important;
    font-weight: 600 !important;
    
}

.dcm-selected-section{
    border-bottom: 3px solid #210062;
    color: #210062;
    font-weight: bold;
}

.dcm-section{
    box-shadow: 0px 3px 11px 1px #f5f5f5;
    position: fixed;
    width: 100vw;
    background-color: #ffffff;
    height: 56px;
    z-index: 999;
}

.dcm-exam-body{
    margin-top: 66px;
}