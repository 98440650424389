.xen-dashboard-sub-nav {
  background-color: white;
  height: calc(100vh - 270px);
  margin-bottom: 1rem;
  margin-top: 1rem;
  position: relative;
  right: 0;
  text-align: left;
  width: 20rem;
  font-size: 14px;
  border-radius: 0px 15px 15px 0px;
  box-shadow: 1px 0px 9px 0px rgb(229 229 229);
  transition: all 0.5s cubic-bezier(0.13, 0.88, 1, 1);
  overflow: auto;
  }

  .xen-dashboard-sub-nav-small {
    background-color: white;
    height: calc(100vh - 270px);
    margin-bottom: 1rem;
    margin-top: 1rem;
    position: relative;
    right: 0;
    text-align: left;
    width: 5.4rem;
    border-radius: 0px 15px 15px 0px;
    box-shadow: 1px 0px 9px 0px rgb(229 229 229);
    transition: all 0.5s cubic-bezier(0.13, 0.88, 1, 1);
    overflow: auto;
  }


  .xen-dash-logo {
    height: 4.4rem;
  }
  .xen-nav-selected {
    border-right: 5px solid #2f3061;
  }
  .xen-border-bottom {
    border-bottom: 2px solid #f2f3f4;
  }
  .xen-dashboard-sub-nav ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 10px;
  }
  .xen-dashboard-sub-nav ul li {
    list-style: none;
  }
  .clrGreen {
    font-size: 1.2rem;
    font-weight: 600;
    z-index: 10;
    color: white;
    margin-top: -30% !important;
  }
  
  .clrRose-hexagon {
    font-size: 1.2rem;
    font-weight: 600;
    z-index: 10;
    margin-top: -4px !important;
  }

  .clrRose {
    font-size: 1.2rem;
    font-weight: 600;
    z-index: 10;
    margin-top: 15% !important;
  }

  .clrOrange {
    background: #ec8234;
  }
  .clrBlueciel {
    background: #21adfc;
  }
  .clrMauve {
    font-size: 1.2rem;
    font-weight: 600;
    color: white !important;
    margin-top: 20% !important;
  }

  .clrMauve-hexagon {
    font-size: 1.2rem;
    font-weight: 600;
    color: white !important;
    margin-top: -4px !important;
  }
  .clrWhite {
    background: #fff;
    border: 2px solid #666;
    color: #666 !important;
  }
  .numberCircle {
    display: flex;
    align-items: center;
    gap: 11px;
  }
  
  .marker {
    margin-top: 10%;
    font-weight: 600;
  }
  .markans{
    margin-top: 20%;
    margin-left: 8%;
    font-weight: 600;
  }
  
  .markedCircle div {
    display: block;
    color: #fff;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #7b5d99;
    border-radius: 50%;
    /* margin-top: 90%; */
    /* margin-left: 50%; */
  }
  
  .hexagon{
    margin-top: 10px;
    width: 40px;
    height: 22px;
    background-color: rgb(10, 207, 36);
    border-color: rgb(10, 207, 36);
    position: relative;
    display: inline-block;
}


.hexagon:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 10px solid;
  border-color: inherit;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  top: -10px;
}

.hexagon:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -10px;
  border-top: 10px solid;
  border-color: inherit;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.hexagon-text {
  color: rgb(10, 207, 36);
}

.answer-review-text {
  color: rgb(131, 32, 243);
}

.circle-blue div {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  height: 40px;
  background: rgb(131, 32, 243);
  border-radius: 50%;
}

  .markedanswer div{
    display: block;
    color: #fff;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #ec8234;
    border-radius: 50%;
    /* margin-top: 40px; */
    /* margin-left: 3%; */
  }

  .clrPurple div{
    display: flex;
    color: #fff;
    width: 40px;
    height: 40px;
    background: #7b5d99;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  
  .answered div {
    background: rgb(10, 207, 36);
    display: flex;
    height: 20px;
    /* margin-left: 20px; */
    /* margin-top: 55px; */
    margin-top: 30px;
    position: relative;
    width: 40px;
  }
  .answered div:before {
    border-bottom: 20px solid rgb(10, 207, 36);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    text-align: center;
    content: "";
    height: 0;
    position: absolute;
    top: -20px;
    width: 40px;
  }
  .xen-question-option-small{
    font-size: 20px;
    font-weight: bold;
    margin-left: 0 !important;
    margin: 0.25rem 0rem 0.25rem 0rem;
  }
  
  
  .notanswered div {
    background: rgb(224, 26, 26);
    display: flex;
    height: 20px;
    /* margin-left: 20px; */
    /* margin-top: 40px; */
    position: relative;
    width: 40px;
  }
  .notanswered div:before {
    border-top: 20px solid rgb(224, 26, 26);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    text-align: center;
    content: "";
    height: 0;
    position: absolute;
    top: 20px;
    width: 40px;
  }
  
  .notvisited div{
    width: 40px;
    height: 40px;
    background: rgb(190, 188, 188);
    border-radius: 5px;
    /* margin-top: 40px;
    margin-left: 25px; */
  }

  .currently-on div{
    width: 40px;
    height: 40px;
    background: white;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    /* margin-top: 40px;
    margin-left: 25px; */
  }

  .currently-on-text{
    color: black;
  }
  .markedanswer-text{
    color: #ec8234;
  }
  .markedCircle-text{
    color: #7b5d99;
  }
  
  .xen-total-questions {
    background-color: #f4faff;
    width: 100%;
    margin-top: 5%;
    /* height: 384px; */
    overflow: hidden;
  }
  .xen-sectionLable {
    cursor: pointer;
  }
  .xen-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .xen-flex{
    display: flex;
  }
  @media only screen and (max-width:1500px){
    .xen-flex{
      display: block;
    }
  }
  
  @media only screen and (max-width: 991px) {
    .xen-dashboard-sub-nav {
      position: fixed;
      right: 0;
      top: 88px;
    }
    .navbar-expand-lg {
      position: absolute;
      top: 0;
      right: 0;
    }
    .xen-top-nav ul li h5 {
      font-size: 1rem;
    }
    .xen-button {
      padding: 10px 10px;
    }
    .xen-timer {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 970px) {
    .xen-dashboard-sub-nav {
      position: fixed;
      right: 0;
      top: 65px;
    }
  }
  .answered,.markedCircle,.notvisited,.notanswered,.markedanswer, .currently-on{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
  
  }
.xcn-button:hover {
  background-color: slategray;
  color: black;
}