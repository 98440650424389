.dcm-exam-list-card{
    border: none !important;
    box-shadow: 0px 0px 10px 4px rgb(112 112 112 / 23%);
}

.dcm-exam-list-card .card-header{
    background: #FCFCFC;
    border: none !important;
    font-size: 20px;
    font-weight: 600;
    color: #4e4e4e;
}

.dcm-table-row-card{
    line-height: 4.5rem;
    min-height: 4.5rem;
    height: 4.5rem;    
    box-shadow: 0px 2px 7px 0px rgb(221 221 221 / 75%);
    margin-top: 1rem;
    
}


.dcm-list-exam-table{
    border-collapse: separate;
    border-spacing: 0 1rem;
}