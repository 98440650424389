.dcm-options-main{
    /* height:calc(100vh - 245px); */
    background-color: #f8f9fa;
    padding: 10px;
    overflow-y: auto;
    /* min-height: 100%; */
    margin-right: 11px;
}
.dcm-body-row-main{
    min-height: 100%
}

.dcm-dicom-view-div{
    max-width: 300px;
    max-height: 300px;
}
.exam-statement{
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100%;
}
.dicom-half-view{
    height: 500px;
    /* overflow-y: auto; */
    margin-bottom: 10px;
}