.xen-top-nav{
    background-color: #fff;
    padding:15px 10px;
    width: 100%;
    position: fixed;
    top: 0;
    height: 70px;
    z-index: 999;
}
.xen-top-nav ul{
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    align-items: center;
    margin: 0;
    }
.xen-top-nav ul li{
list-style: none;
color: #ffffff;
}
.xen-top-nav ul li h5{
    margin: auto;
}
.xen-timer{
    font-size: 1.2rem;
    font-weight: 600;
}
.xen-timer span{
    background-color: #ffffff;
    color:#000000;
    padding: 10px;

}

.fnt{
    font-weight: 600;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }



.breathing-button {
    padding: 10px;
    -webkit-animation: breathing 3s ease-out infinite normal;
    animation: breathing 3s ease-out infinite normal;
    font-size: 15px;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    border-radius: 3px;
    text-align: center;
}
    
/* .breathing-button::after {
content: "breathe in";
} */

@-webkit-keyframes breathing {
0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

25% {
    -webkit-transform: scale(1);
    transform: scale(1);
}

60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}
}

/* @keyframes breathing::after {
    0%::after {
        content: "test";
    }
} */

@keyframes breathing {
0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}
}

.dcm-client-icon{
    height: 2rem !important;
    width: 2rem !important;
}

.popover-text-user-data{
    text-align: left !important;
    min-width: 190px;
    padding: 5px;
    font-size: 10px !important;
}